/* styles/globals.css */
body {
  background-color: #0c42ae;
    color: white;
    padding: 40px;
    font-family: Sans-Serif;
    -webkit-user-select: none; /* Safari */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
    cursor: default;
  font-family: Arial, sans-serif;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: blue;
}

nav ul li a:hover {
  text-decoration: underline;
}

form div {
  margin-bottom: 10px;
}
